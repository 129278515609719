import ApiService from "@/common/api/api.service";
import helpers from "../../utils/helpers";

const ProgramacaoService = {
  objectToQueryString(obj) {
    var str = [];
    for (var p in obj) {
      if (Array.isArray(obj[p])) {
        obj[p].forEach((item) => {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(item));
        });
        continue;
      }
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }

    return str.join('&');
  },

  listarTitulos(form) {
    form.CpfCnpjSacado = form.CpfCnpjSacado
      ? helpers.removerMascara(form.CpfCnpjSacado)
      : "";
    // form.CpfCnpjCedente = form.CpfCnpjCedente ? helpers.removerMascara(form.CpfCnpjCedente) : '';
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/Relatorio/titulos?${sendForm}`);
  },

  programarPagamento(form) {
    console.log("form", form)
    return ApiService.post(`/Relatorio`, form);
  },
  atualizarStatus(form) {
    console.log("form", form)
    return ApiService.put(`/Relatorio`, form);
  },
  buscarProgramacao(form) {
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/Relatorio?${sendForm}`);
  },
  baixarExcel(form) {
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/Relatorio/html-excel?${sendForm}`);
  },
  removerProgramacao(id) {
    console.log('removerProgramacao', id)
    return ApiService.delete(`/Relatorio/${id}`);
  },
  obterContaGrafica(form) {
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/ContaCorrente?${sendForm}`);
    //return ApiService.get(`/ContaGrafica?${sendForm}`);
  },
  obterCedentesDropDownPorConsultor(codConsultor) {
    return ApiService.get(`/Cedente/dropdown?codigoAgente=${codConsultor}`);
  },
  obterTotalDebito(cpfCnpj) {
    return ApiService.get(`/ContaCorrente/dashboard-conta-grafica?cpfCnpj=${cpfCnpj}`);
  }
};

export default ProgramacaoService;
