import ApiService from "@/common/api/api.service";

const RotuloBoletosService = {
  objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));

    return str.join("&");
  },
  listar(form) {
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/rotulo-boletos?${sendForm}`);
  },
  buscarPorId(id) {
    return ApiService.get(`/rotulo-boletos/${id}`, "", { noToast: true });
  },
  criar(form) {
    return ApiService.post("/rotulo-boletos", form);
  },
  editar(form) {
    return ApiService.put("/rotulo-boletos", form);
  },
  excluir(id) {
    return ApiService.delete(`/rotulo-boletos/${id}`, "");
  },
  listarComercial(form) {
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/rotulo-boletos/titulos-comercial?${sendForm}`);
  },
};

export default RotuloBoletosService;